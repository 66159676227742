import SEO from '@components/SEO';
import IconArrow2 from '@icons/IconArrow2';
import { userService } from '@services/userService';
import ContentLoading from '@views_admin/ContentLoading';
import React, { memo, useEffect, useState } from 'react';
import { navigate } from "gatsby";
import constant from '@utils/constant';
import Footer from '@views/default-layout/footer/Footer';

const ActivateStatus = {
   SUCCESS: 'SUCCESS',
   ACTIVATED: 'ACTIVATED', // account was activated
   INVALID: 'INVALID',  // code is invalid or expired
}

const ActivationWithCode = memo((props) => {
   const code = props.code;
   const [activating, setActivating] = useState(true);
   const [status, setStatus] = useState('');

   useEffect(() => {
      const activeAccount = async () => {
         await setActivating(true);
         const response = await userService.activateUser(code);
         if (response.isSuccess) {
            setStatus(ActivateStatus.SUCCESS);
         } else {
            if (response.message === 'Account was activated') {
               setStatus(ActivateStatus.ACTIVATED);
            }
            else if (response.message === 'Invalid activation code'
               || response.message === 'Activation code is invalid or expired') {
               setStatus(ActivateStatus.INVALID);
            } else {
               navigate(constant.ROUTE_ACTIVATE);
               return;
            }
         }
         setActivating(false);
      }
      activeAccount();
   },
      [code]
   )

   const handleClick = () => {
      if (status === ActivateStatus.SUCCESS || status === ActivateStatus.ACTIVATED) {
         navigate(constant.ROUTE_PRODUCTS);
      } else if (status === ActivateStatus.INVALID) {
         navigate(constant.ROUTE_ACTIVATE);
      } else {
         navigate(constant.ROUTE_HOME);
      }
   }

   return (
      <>
         <SEO
            title={'Activate Account'}
         />
         {
            activating ?
               <ContentLoading isLoading={true} />
               :
               <>
                  <div className="oauth-sub-page activation-page">
                     <div className="section-title">
                        <span className="section-title-name animate__animated animate__fadeIn">Activate account
                           <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow"><IconArrow2 /></span>
                        </span>
                        <div className="section-title--desc">
                           {{
                              [ActivateStatus.SUCCESS]: 'Your account has been activated successfully!',
                              [ActivateStatus.ACTIVATED]: 'Your account was activated.',
                              [ActivateStatus.INVALID]: 'Activation code is invalid or has expired.',
                           }[status]}
                        </div>
                     </div>
                     <button className="common-button" type="primary"
                        onClick={handleClick}
                     >
                        {{
                           [ActivateStatus.SUCCESS]: 'Continue Shopping',
                           [ActivateStatus.ACTIVATED]: 'Continue Shopping',
                           [ActivateStatus.INVALID]: 'Request re-activation',
                        }[status]}
                     </button>
                  </div>
                  <Footer />
               </>
         }
      </>
   );
});

export default ActivationWithCode;